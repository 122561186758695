
import ActionAlertDialog from 'components/dist/molecules/ActionAlertDialog';
import RenameAlertDialog from 'components/dist/molecules/RenameAlertDialog';
import AddFilesToUserConfirmation from 'components/dist/organisms/AddFilesToUserConfirmation';
import { GlobalShoeBoxProps } from 'components/dist/organisms/GlobalShoeBox';
import PickLoanToMoveToConfirmation from 'components/dist/organisms/PickLoanToMoveToConfirmation';
import { useRouter } from 'next/router';
import React, { useCallback, useMemo } from 'react';
import { api } from 'src/api';
import { AppUserDTO2, LoanDto, LoanPhaseCategoryType, ShoeboxType } from 'src/backend';
import { MoveFormElementsDialog } from 'src/components/form-elements/move-form-elements-dialog';
import { RoleTypeLabel } from 'src/constants/loan';
import { QUERY_SHOEBOX_ITEM_PREVIEW_ID } from 'src/constants/shoebox';
import { useUser } from 'src/hooks/use-user';
import { useDeleteShoeBoxItemMutation, useUpdateShoeBoxItemMutation } from 'src/services/lenderShoeBoxApi';
import { useGetLoansViewQuery } from 'src/services/loanApi';
import { getLoan } from 'src/slices/loan';
import { getLoanShoeBoxItems } from 'src/slices/shoebox';
import { useDispatch } from 'src/store';
import { getInitials } from 'src/utils/get-initials';
import { filterActivePhaseCategory } from 'src/utils/loan/filter-active-phase-category';
import { getReviewStatusChipLabel } from 'src/utils/loan/get-review-status-chip-label';
import { getReviewStatusTooltip } from 'src/utils/loan/get-review-status-tooltip';
import { toast } from 'src/utils/toast';
import { getUserDisplayName } from 'src/utils/user/get-user-display-name';
import { isRoleABorrower } from 'src/utils/user/is-role-a-borrower';

const transformUser = (user: AppUserDTO2): GlobalShoeBoxProps['loans'][0]['users'][0] => {
    if (!user) {
        return null
    }
    return ({
        id: user.id,
        givenName: user.givenName,
        name: getUserDisplayName(user),
        email: user.emailAddress,
        avatarUrl: "",
        filesCount: 0,
        initials: getInitials(getUserDisplayName(user)),
        role: RoleTypeLabel(user.roleDefault)
    })
}

export const transformedLoanDtoToLoan = (loans: LoanDto[], userId: string): GlobalShoeBoxProps['loans'] => {
    return loans.map<GlobalShoeBoxProps['loans'][0]>((loan) => ({
        id: loan.id,
        shortCode: loan.shortCode,
        projectName: loan.projectName,
        loanPurpose: loan.loanPurpose,
        loanType: loan.templateProjectName,
        iAmAdded: loan.loanRoles.some((loanRole => loanRole.user.id === userId)),
        loanStatus: {
            tooltip: getReviewStatusTooltip(loan.reviewStatus),
            status: loan.reviewStatus,
            label: getReviewStatusChipLabel(loan.reviewStatus),
        },
        users: loan.loanRoles.map(loanRole => ({
            id: loanRole.user.id,
            givenName: loanRole.user.givenName,
            initials: getInitials(getUserDisplayName(loanRole.user)),
            avatarUrl: "",
            filesCount: 0,
            name: getUserDisplayName(loanRole.user),
            email: loanRole.user.emailAddress,
            role: RoleTypeLabel(loanRole.role)
        }))
    }))
}

const TOAST_DURATION = 10_000;

const ShoeboxItemViewerContext = React.createContext<{
    moveToLoanPackage: {
        loanId: string | null,
        file: GlobalShoeBoxProps['folders'][0]['files'][0] | null,
    },
    stagedShoeBoxItemToDelete: GlobalShoeBoxProps['folders'][0]['files'][0] | null
    stagedShoeBoxItemToRename: GlobalShoeBoxProps['folders'][0]['files'][0] | null
    pickLenderLoan: {
        loan: GlobalShoeBoxProps['loans'][0] | null,
        file: GlobalShoeBoxProps['folders'][0]['files'][0] | null,
    }
    onRenameShoeBoxFile: (file: GlobalShoeBoxProps['folders'][0]['files'][0], newName: string) => Promise<void>
    onConfirmDeleteShoeBoxItem: (file: GlobalShoeBoxProps['folders'][0]['files'][0]) => Promise<void>
    loans: GlobalShoeBoxProps['loans']
    me: GlobalShoeBoxProps['me'] | null
    onOpenShoeBoxFileInNewTab: (file: GlobalShoeBoxProps['folders'][0]['files'][0]) => void
    user: AppUserDTO2 | null
    onMoveShoeBoxFilesToLoan: (loan: GlobalShoeBoxProps['loans'][0], userId: string, files: GlobalShoeBoxProps['folders'][0]['files'][0] | null) => Promise<void>
    onSetPickLenderLoan: (loan: GlobalShoeBoxProps['loans'][0] | null, files: GlobalShoeBoxProps['folders'][0]['files'][0] | null) => Promise<void>
    setMoveToLoanShoeBoxFile: (files: GlobalShoeBoxProps['folders'][0]['files'][0] | null) => void
    setMoveToLoanPackage: (loanId: string | null, files: GlobalShoeBoxProps['folders'][0]['files'][0] | null) => void
    setStagedShoeBoxItemToDelete: (files: GlobalShoeBoxProps['folders'][0]['files'][0] | null) => void
    setStagedShoeBoxItemToRename: (file: GlobalShoeBoxProps['folders'][0]['files'][0] | null) => void
    setPickLenderLoan: (loan: GlobalShoeBoxProps['loans'][0] | null, files: GlobalShoeBoxProps['folders'][0]['files'][0] | null) => void
}>({
    moveToLoanPackage: {
        loanId: null,
        file: null,
    },
    stagedShoeBoxItemToDelete: null,
    stagedShoeBoxItemToRename: null,
    pickLenderLoan: {
        loan: null,
        file: null,
    },
    user: null,
    onSetPickLenderLoan: async () => { },
    loans: [],
    onOpenShoeBoxFileInNewTab: () => { },
    me: null,
    onMoveShoeBoxFilesToLoan: async () => { },
    onRenameShoeBoxFile: async () => { },
    onConfirmDeleteShoeBoxItem: async () => { },
    setMoveToLoanShoeBoxFile: () => { },
    setMoveToLoanPackage: () => { },
    setStagedShoeBoxItemToDelete: () => { },
    setStagedShoeBoxItemToRename: () => { },
    setPickLenderLoan: () => { },
});


type State = {
    moveToLoanPackage: {
        file: GlobalShoeBoxProps['folders'][0]['files'][0] | null,
        loanId: string | null
    },
    moveToLoanShoeBoxFile: GlobalShoeBoxProps['folders'][0]['files'][0] | null
    stagedShoeBoxItemToDelete: GlobalShoeBoxProps['folders'][0]['files'][0] | null
    stagedShoeBoxItemToRename: GlobalShoeBoxProps['folders'][0]['files'][0] | null
    pickLenderLoan: {
        loan: GlobalShoeBoxProps['loans'][0] | null,
        file: GlobalShoeBoxProps['folders'][0]['files'][0] | null,
    }
};

// create reducer to handle actions with types
const initialState: State = {
    moveToLoanPackage: {
        file: null,
        loanId: null
    },
    moveToLoanShoeBoxFile: null,
    stagedShoeBoxItemToDelete: null,
    stagedShoeBoxItemToRename: null,
    pickLenderLoan: {
        loan: null,
        file: null,
    },
};

const actions = {
    SET_MOVE_TO_LOAN_SHOEBOX_FILE: 'SET_MOVE_TO_LOAN_SHOEBOX_FILE',
    SET_MOVE_TO_LOAN_PACKAGE_SHOEBOX_FILE: 'SET_MOVE_TO_LOAN_PACKAGE_SHOEBOX_FILE',
    SET_STAGED_SHOEBOX_ITEM_TO_DELETE: 'SET_STAGED_SHOEBOX_ITEM_TO_DELETE',
    SET_STAGED_SHOEBOX_ITEM_TO_RENAME: 'SET_STAGED_SHOEBOX_ITEM_TO_RENAME',
    SET_PICK_LENDER_LOAN: 'SET_PICK_LENDER_LOAN',
} as const;

type Action =
    | {
        type: typeof actions.SET_MOVE_TO_LOAN_PACKAGE_SHOEBOX_FILE; payload: {
            loanId: string | null,
            file: GlobalShoeBoxProps['folders'][0]['files'][0] | null
        }
    }
    | { type: typeof actions.SET_MOVE_TO_LOAN_SHOEBOX_FILE; payload: GlobalShoeBoxProps['folders'][0]['files'][0] | null }
    | { type: typeof actions.SET_STAGED_SHOEBOX_ITEM_TO_DELETE; payload: GlobalShoeBoxProps['folders'][0]['files'][0] | null }
    | { type: typeof actions.SET_STAGED_SHOEBOX_ITEM_TO_RENAME; payload: GlobalShoeBoxProps['folders'][0]['files'][0] | null }
    | {
        type: typeof actions.SET_PICK_LENDER_LOAN; payload: {
            loan: GlobalShoeBoxProps['loans'][0] | null,
            file: GlobalShoeBoxProps['folders'][0]['files'][0] | null
        }
    }

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case actions.SET_MOVE_TO_LOAN_SHOEBOX_FILE:
            return {
                ...state,
                moveToLoanShoeBoxFile: action.payload,
            };
        case actions.SET_MOVE_TO_LOAN_PACKAGE_SHOEBOX_FILE:
            return {
                ...state,
                moveToLoanPackage: action.payload,
            };
        case actions.SET_STAGED_SHOEBOX_ITEM_TO_DELETE:
            return {
                ...state,
                stagedShoeBoxItemToDelete: action.payload,
            };
        case actions.SET_STAGED_SHOEBOX_ITEM_TO_RENAME:
            return {
                ...state,
                stagedShoeBoxItemToRename: action.payload,
            };
        case actions.SET_PICK_LENDER_LOAN:
            return {
                ...state,
                pickLenderLoan: action.payload,
            };
        default:
            return state;
    }
};

export const ShoeBoxItemProvider = (props: React.PropsWithChildren<object>) => {
    const userState = useUser();
    const router = useRouter();
    const me = transformUser(userState.user);
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const [updateShoeBoxItem] = useUpdateShoeBoxItemMutation();
    const reduxDispatch = useDispatch();
    const [deleteShoeBoxItem] = useDeleteShoeBoxItemMutation();
    // only get archive category for lenders
    const categories = [
        LoanPhaseCategoryType.LEAD,
        LoanPhaseCategoryType.ORIGINATION,
        LoanPhaseCategoryType.PORTFOLIO,
    ]
    if (userState.isLender) {
        categories.push(LoanPhaseCategoryType.ARCHIVE)
    }
    const { data: loans = [], refetch: refetchLoans } = useGetLoansViewQuery({
        categories
    }, {
        skip: !userState.user,
    })

    const setStagedShoeBoxItemToDelete = (file: GlobalShoeBoxProps['folders'][0]['files'][0]) => {
        dispatch({ type: actions.SET_STAGED_SHOEBOX_ITEM_TO_DELETE, payload: file });
    }

    const setStagedShoeBoxItemToRename = (file: GlobalShoeBoxProps['folders'][0]['files'][0]) => {
        dispatch({ type: actions.SET_STAGED_SHOEBOX_ITEM_TO_RENAME, payload: file });
    }

    const setPickLenderLoan = (loan: GlobalShoeBoxProps['loans'][0] | null, file: GlobalShoeBoxProps['folders'][0]['files'][0] | null) => {
        dispatch({ type: actions.SET_PICK_LENDER_LOAN, payload: { loan, file } });
    }

    const setMoveToLoanShoeBoxFile = useCallback((file: GlobalShoeBoxProps['folders'][0]['files'][0]) => {
        dispatch({ type: actions.SET_MOVE_TO_LOAN_SHOEBOX_FILE, payload: file });
        refetchLoans()
    }, [refetchLoans]);

    const setMoveToLoanPackage = useCallback((loanId: string | null, file: GlobalShoeBoxProps['folders'][0]['files'][0] | null) => {
        dispatch({
            type: actions.SET_MOVE_TO_LOAN_PACKAGE_SHOEBOX_FILE,
            payload: {
                file,
                loanId
            }
        });
    }, []);

    const onAddMeToLoan = useCallback(async (loanId: string) => {
        await api.addLender(loanId, {
            appUser: me.id,
            canAcceptFiles: null,
            leadBorrower: null,
            leadLender: null,
            newToLoan: true,
            borrowerType: null,
            role: userState.user.roleDefault,
            visibleToBorrower: null
        })
    }, [me?.id, userState.user?.roleDefault]);

    const refreshLoan = useCallback(async (loanId: string) => {
        reduxDispatch(getLoan(loanId));
        reduxDispatch(getLoanShoeBoxItems(loanId));
    }, [reduxDispatch]);

    const moveShoeBoxFilesToLoan = useCallback(async (
        loan: GlobalShoeBoxProps['loans'][0],
        userId: string,
        file: GlobalShoeBoxProps['folders'][0]['files'][0] | null) => {
        await updateShoeBoxItem({
            documentId: file.documentId,
            id: file.id,
            loanId: loan.id,
            shoeboxOwnerId: userId,
            shoeboxType: ShoeboxType.LOAN,
            title: file.title,
            uploadedById: file.uploadedById,
            userId,
        })
        if (file.loanId) {
            refreshLoan(file.loanId);
        }
        toast({
            type: 'success',
            duration: TOAST_DURATION,
            content: `${file.title} added to ${loan.shortCode} ${loan.projectName} successfully`

        })
    }, [updateShoeBoxItem, refreshLoan]);

    const onMoveShoeBoxFilesToLoan = useCallback(async (
        loan: GlobalShoeBoxProps['loans'][0],
        userId: string,
        file: GlobalShoeBoxProps['folders'][0]['files'][0] | null,
    ) => {
        setPickLenderLoan(null, null);
        setMoveToLoanShoeBoxFile(null)
        try {
            if (userId === me.id && !loan.users.some((user) => user.id === me.id)) {
                // wait for user to be added to loan
                await onAddMeToLoan(loan.id);
            }
            await moveShoeBoxFilesToLoan(loan, userId, file);
        } catch (error) {
            console.error(error);
        }
    }, [setMoveToLoanShoeBoxFile, me?.id, moveShoeBoxFilesToLoan, onAddMeToLoan]);

    const onSetPickLenderLoan = useCallback(async (
        loan: GlobalShoeBoxProps['loans'][0] | null,
        file: GlobalShoeBoxProps['folders'][0]['files'][0] | null,
    ) => {
        if (loan?.users.some((user) => user.id === me.id)) {
            await onMoveShoeBoxFilesToLoan(loan, me.id, file);
        } else {
            setPickLenderLoan(loan, file);
        }
    }, [onMoveShoeBoxFilesToLoan, me?.id]);

    const onOpenShoeBoxFileInNewTab = (file: GlobalShoeBoxProps['folders'][0]['files'][0]) => {
        try {
            // parse search params from window.search
            const searchParams = new URLSearchParams(window.location.search);
            // create a new URL object with the current origin and pathname
            const url = new URL(`${window.location.origin}${window.location.pathname}`);
            [...searchParams.entries()].forEach(([key, value]) => {
                // set the query params
                url.searchParams.set(key, value);
            })
            url.searchParams.set(QUERY_SHOEBOX_ITEM_PREVIEW_ID, file.id);
            // open the url in a new tab
            window.open(url.toString(), '_blank');
        } catch (error) {
            console.error(error);
        }
    }

    const onConfirmDeleteShoeBoxItem = useCallback(async (file: GlobalShoeBoxProps['folders'][0]['files'][0]) => {
        await deleteShoeBoxItem({
            id: file.id
        })
        if (file.loanId) {
            refreshLoan(file.loanId);
        }
        toast({
            type: 'success',
            duration: TOAST_DURATION,
            content: `${file.title} deleted successfully`
        })
        // remove the query param
        router.replace({
            pathname: router.pathname,
            query: {
                ...router.query,
                [QUERY_SHOEBOX_ITEM_PREVIEW_ID]: undefined
            }
        }, undefined, { shallow: true })
    }, [deleteShoeBoxItem, refreshLoan, router]);

    const onRenameShoeBoxFile = useCallback(async (file: GlobalShoeBoxProps['folders'][0]['files'][0], newName: string) => {
        await updateShoeBoxItem({
            documentId: file.documentId,
            id: file.id,
            loanId: file.loanId,
            shoeboxOwnerId: userState.user.id,
            shoeboxType: file.shoeboxType,
            title: newName,
            uploadedById: file.uploadedById,
            userId: userState.user.id,
        })
        if (file.loanId) {
            refreshLoan(file.loanId);
        }
        toast({
            type: 'success',
            duration: TOAST_DURATION,
            content: `${file.title} renamed to ${newName} successfully`
        })
    }, [refreshLoan, updateShoeBoxItem, userState.user?.id]);

    const filteredLoans = loans.filter(filterActivePhaseCategory);
    const mappedLoans = transformedLoanDtoToLoan(filteredLoans, userState.user?.id);


    const value = useMemo(() => {
        return {
            ...state,
            me,
            user: userState.user,
            loans: mappedLoans,
            setMoveToLoanShoeBoxFile,
            setMoveToLoanPackage,
            onOpenShoeBoxFileInNewTab,
            setStagedShoeBoxItemToDelete,
            setStagedShoeBoxItemToRename,
            setPickLenderLoan,
            onRenameShoeBoxFile,
            onSetPickLenderLoan,
            onMoveShoeBoxFilesToLoan,
            onConfirmDeleteShoeBoxItem,
        };
    }, [mappedLoans, me, onConfirmDeleteShoeBoxItem, onMoveShoeBoxFilesToLoan, onRenameShoeBoxFile, onSetPickLenderLoan, setMoveToLoanPackage, setMoveToLoanShoeBoxFile, state, userState.user]);

    return (
        <ShoeboxItemViewerContext.Provider value={value}>
            {props.children}
            <PickLoanToMoveToConfirmation
                hideLoanFilter={isRoleABorrower(userState.user?.roleDefault)}
                scrollableTarget='shoebox-item-viewer-scrollable-target-shoebox-item'
                items={[state.moveToLoanShoeBoxFile]}
                open={!!state.moveToLoanShoeBoxFile}
                loans={mappedLoans}
                onOpenChange={(open) => {
                    if (!open) {
                        setMoveToLoanShoeBoxFile(null)
                    }
                }}
                onMoveConfirm={(loan, [item]) => {
                    onSetPickLenderLoan(
                        loan,
                        item,
                    );
                }}
            />
            <AddFilesToUserConfirmation
                open={!!state.pickLenderLoan.loan}
                onOpenChange={(open) => {
                    if (!open) {
                        setPickLenderLoan(null, null)
                    }
                }}
                loan={state.pickLenderLoan.loan}
                items={[state.pickLenderLoan.file]}
                me={me}
                onConfirm={(loan, userId, [item]) => {
                    onMoveShoeBoxFilesToLoan(loan, userId, item);
                }}
            />
            <ActionAlertDialog
                ariaLabel={"Are you sure you want to delete"}
                message={`Are you sure you want to delete ${state.stagedShoeBoxItemToDelete?.title}`}
                open={!!state.stagedShoeBoxItemToDelete}
                onConfirm={() => {
                    onConfirmDeleteShoeBoxItem(state.stagedShoeBoxItemToDelete);
                }}
                onOpenChange={(open) => {
                    if (!open) {
                        setStagedShoeBoxItemToDelete(null)
                    }
                }}
            />
            <RenameAlertDialog
                open={!!state.stagedShoeBoxItemToRename}
                onOpenChange={(open) => {
                    if (!open) {
                        setStagedShoeBoxItemToRename(null)
                    }
                }}
                onRename={(newName) => {
                    onRenameShoeBoxFile(state.stagedShoeBoxItemToRename, newName);
                }}
                name={state.stagedShoeBoxItemToRename?.title || ""}
            />
            {state.moveToLoanPackage.loanId && <MoveFormElementsDialog
                open={!!state.moveToLoanPackage.loanId}
                loanId={state.moveToLoanPackage.loanId}
                onOpenChange={(open) => {
                    if (!open) {
                        setMoveToLoanPackage(null, null)
                    }
                }}
                type="shoeboxItem"
                shoeBoxFiles={[state.moveToLoanPackage.file]} />}
        </ShoeboxItemViewerContext.Provider>
    );
};

export const useShoeBoxItemViewerContext = () => {
    const context = React.useContext(ShoeboxItemViewerContext);
    if (context === undefined) {
        throw new Error(
            'useShoeBoxItemViewerContext must be used within a ShoeBoxItemProvider',
        );
    }
    return context;
};
